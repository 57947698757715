import { Checkboxes, Fieldset, Radios } from "nhsuk-react-components";

import { GetCollectionsApiResponse } from '@forms/console-types/api-responses/get-collections';
import { UserRole } from '@forms/console-types';
import styles from './roles-select.module.scss';

interface RolesSelectFormProps {
    data: GetCollectionsApiResponse;
    handleChange: (e: any) => void;
}

export const RolesSelect = ({ data, handleChange }: RolesSelectFormProps): JSX.Element => {

    const collectionsSelect = (): JSX.Element => (
      <Fieldset>
          <Fieldset.Legend>
              Select collections that the user can access
          </Fieldset.Legend>
          <Checkboxes
            name="collections"
            id="collections"
          >
              {
                  data?.collections.map((item) => (
                    <Checkboxes.Box key={item.id} value={item.id} onChange={handleChange}>{item.name}</Checkboxes.Box>
                  ))
              }
          </Checkboxes>
      </Fieldset>
    )

    return (
        <Fieldset>
            <Fieldset.Legend>Roles</Fieldset.Legend>
            <Radios
              className={styles.radios}
              name="role selection"
              id="role-selection"
              hint="Select a role for the user"
              onChange={handleChange}
            >
                <Radios.Radio id={UserRole.Admin} value={UserRole.Admin}>
                    {UserRole.Admin}
                </Radios.Radio>
                <Radios.Radio id={UserRole.Console} value={UserRole.Console}>
                    {UserRole.Console}
                </Radios.Radio>
                <Radios.Radio
                  id={UserRole.Business}
                  value={UserRole.Business}
                  conditional={collectionsSelect()}
                >
                    {UserRole.Business}
                </Radios.Radio>
            </Radios>
        </Fieldset>
    )
}
