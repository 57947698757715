import { Breadcrumb } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import React from 'react';
import { selectBreadcrumbs } from 'store/bread-crumbs.slice';
import styles from './app-breadcrumb.module.scss';
import { useAppSelector } from 'app/hooks';

const AppBreadcrumb = () => {
    const breadcrumbs = useAppSelector(selectBreadcrumbs);

    const backcrumb =
        breadcrumbs.length > 1
            ? breadcrumbs[breadcrumbs.length - 2]
            : {
                  display: 'Home',
                  path: '/'
              };

    return (
        <Breadcrumb className={`${styles.appBreadcrumb}`}>
            <Breadcrumb.Item to="/" asElement={Link}>
                Home
            </Breadcrumb.Item>

            {breadcrumbs.map(({ display, path }) => (
                <Breadcrumb.Item key={path} to={path} asElement={Link}>
                    {display}
                </Breadcrumb.Item>
            ))}

            <Breadcrumb.Back to={backcrumb.path} asElement={Link}>
                Back to {backcrumb.display}
            </Breadcrumb.Back>
        </Breadcrumb>
    );
};

export default AppBreadcrumb;
