import { Header } from 'nhsuk-react-components';
import React from 'react';

const AppHeader = () => {
    return (
        <>
            <Header transactional>
                <Header.Container className="nhsuk-width-container-fluid">
                    <Header.Logo href="/" />
                    <Header.ServiceName href="/">DPS Forms Management Console</Header.ServiceName>
                </Header.Container>
            </Header>
        </>
    );
};

export default AppHeader;
