import { ChevronLeftIcon } from 'nhsuk-react-components';
import styles from './sort-head-button.module.scss';
import React from 'react';

const SortHeadButton = ({ columnId, display, setSort, sortDirection, sortField }) => {
    const handleClick = () => {
        setSort(columnId);
    };

    return (
        <div className={styles.sortHeadButton}>
            <button
                className={`${styles.button} nhsuk-u-padding-right-3`}
                id={columnId}
                onClick={handleClick}
            >
                {display}
                {sortField === columnId ? (
                    sortDirection === 'asc' ? (
                        <span className={styles.asc}>
                            <ChevronLeftIcon className={styles.arrow} />
                        </span>
                    ) : (
                        <span className={styles.desc}>
                            <ChevronLeftIcon className={styles.arrow} />
                        </span>
                    )
                ) : (
                    <div className={styles.none}>
                        <ChevronLeftIcon className={styles.arrow} />
                        <ChevronLeftIcon className={styles.arrow} />
                    </div>
                )}
            </button>
        </div>
    );
};

export default SortHeadButton;
