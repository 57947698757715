import { clearApiError, selectErrorState } from 'store/error.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { Button } from '@app';
import { ErrorPage } from "components/error";
import React from "react";
import { useHistory } from 'react-router-dom';

interface ErrorProviderProps {
    children: React.ReactElement
}

export default function ErrorProvider({ children }: ErrorProviderProps) {
    const history = useHistory();
    const { apiError } = useAppSelector(selectErrorState);
    const dispatch = useAppDispatch();

    const handleClick = (evt: React.MouseEvent) => {
        dispatch(clearApiError());
        history.push('/');
    } 
    
    if (!apiError) {
        return children;
    }

    return (
        <>
            <ErrorPage />
            <Button onClick={handleClick}>Click here to return to home</Button>
        </>
    )
}
