import { AddUserPage, ConsoleUsersPage } from '@users';
import { AppBreadcrumb, AppFooter, AppHeader } from '@app';
import { DirectAccessLinksCreatePage, DirectAccessLinksPage } from 'components/direct-access-links';
import { LoginPage, Oauth2CallbackPage, PrivateRoute } from 'components/auth';
import { Route, Switch } from 'react-router-dom';
import { SubmittersImportFeedbackPage, SubmittersImportPage, SubmittersPage, ViewSubmitterPage } from '@submitters';

import AuthProvider from '../auth-provider/auth-provider';
import ConfigProvider from '../config-provider/config-provider';
import { ErrorPage } from 'components/error';
import ErrorProvider from '../error-provider/error-provider';
import { HomePage } from '@home';
import { ManageSubmitterPage } from 'components/submitters/manage-submitter-page/manage-submitter-page';
import { NotFoundPage } from 'components/not-found';
import { Permission } from '@forms/console-types';

function App() {
    return (
        <div className="App">
            <AppHeader />
            <AppBreadcrumb />
            <div id="container">
                <section>
                    <div className="nhsuk-width-container-fluid">
                        <div
                            className="nhsuk-main-wrapper nhsuk-u-clear"
                            id="maincontent"
                            role="main"
                        >
                            <ConfigProvider>
                                <AuthProvider>
                                    <ErrorProvider>
                                        <Switch>
                                            <PrivateRoute path="/" exact>
                                                <HomePage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/users"
                                                exact
                                                hasPermission={Permission.ConsoleUserList}
                                            >
                                                <ConsoleUsersPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/users/add"
                                                exact
                                                hasPermission={Permission.ConsoleUserCreate}
                                            >
                                                <AddUserPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/submitters"
                                                exact
                                                hasPermission={Permission.SubmitterUserList}
                                            >
                                                <SubmittersPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/submitters/import"
                                                exact
                                                hasPermission={Permission.SubmitterUserImport}
                                            >
                                                <SubmittersImportPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/submitters/:sub/manage"
                                                hasPermission={Permission.SubmitterUserEdit}
                                            >
                                                <ManageSubmitterPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/submitters/:sub"
                                                exact
                                                hasPermission={Permission.SubmitterUserView}
                                            >
                                                <ViewSubmitterPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/submitters/import/feedback/:id"
                                                exact
                                                hasPermission={Permission.SubmitterUserImport}
                                            >
                                                <SubmittersImportFeedbackPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/direct-access-links"
                                                exact
                                                hasPermission={Permission.DirectAccessLinksManage}
                                            >
                                                <DirectAccessLinksPage />
                                            </PrivateRoute>
                                            <PrivateRoute
                                                path="/direct-access-links/create"
                                                exact
                                                hasPermission={Permission.DirectAccessLinksManage}
                                            >
                                                <DirectAccessLinksCreatePage />
                                            </PrivateRoute>
                                            <Route path="/login" exact>
                                                <LoginPage />
                                            </Route>
                                            <Route path="/oauth2/callback" exact>
                                                <Oauth2CallbackPage />
                                            </Route>
                                            <Route path="/error" exact>
                                                <ErrorPage />
                                            </Route>
                                            <Route>
                                                <NotFoundPage />
                                            </Route>
                                        </Switch>
                                    </ErrorProvider>
                                </AuthProvider>
                            </ConfigProvider>
                        </div>
                    </div>
                </section>
            </div>
            <AppFooter />
        </div>
    );
}

export default App;
