import { Breadcrumb, setBreadcrumbs } from 'store/bread-crumbs.slice';

import { useAppDispatch } from 'app/hooks';
import { useEffect } from 'react';

export function useBreadcrumbs(breadcrumbs: Breadcrumb[]) {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbs));
    });
}
