import { Card } from 'nhsuk-react-components';
import React from 'react';

export const NoAccessCard = () => {
    return (
        <>
            <Card.Group>
                <Card.GroupItem width="one-half">
                    <Card>
                        <Card.Content>
                            <Card.Heading className="nhsuk-heading-m">No permissions</Card.Heading>
                            <Card.Description>
                                You don't have any roles assigned. Please contact your sponsor to be
                                assigned a role.
                            </Card.Description>
                        </Card.Content>
                    </Card>
                </Card.GroupItem>
            </Card.Group>
        </>
    );
};
export default NoAccessCard;
