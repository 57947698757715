import React, { useRef, useEffect, useState } from 'react';
import { Table } from 'nhsuk-react-components';
import SortHeadButton from './button/sort-head-button';
import { orderBy } from 'lodash';
import { nanoid } from 'nanoid';

const SortHead = ({
    sortHeadSpecs,
    setResultsData,
    resultsData,
    initialSortBy,
    initialSortDirection,
    children
}) => {
    const hasSortedData = useRef(false);
    const [sortField, setSortField] = useState(initialSortBy);
    const [sortDirection, setSortDirection] = useState(initialSortDirection);

    useEffect(() => {
        if (!hasSortedData.current) {
            setResultsData(orderBy(resultsData, initialSortBy, initialSortDirection));
            hasSortedData.current = true;
        }
    }, [setResultsData, initialSortBy, initialSortDirection, resultsData]);

    const setSort = headerId => {
        let nextSortDirection;
        if (headerId === sortField) {
            nextSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            nextSortDirection = 'asc';
            setSortField(headerId);
        }
        setSortDirection(nextSortDirection);
        setResultsData(orderBy(resultsData, headerId, nextSortDirection));
    };

    return (
        <Table.Head>
            <Table.Row>
                {sortHeadSpecs &&
                    sortHeadSpecs.map(({ id, display }) => (
                        <Table.Cell key={nanoid()}>
                            <SortHeadButton
                                columnId={id}
                                display={display}
                                setSort={setSort}
                                sortDirection={sortDirection}
                                sortField={sortField}
                            />
                        </Table.Cell>
                    ))}
                {children}
            </Table.Row>
        </Table.Head>
    );
};

export default SortHead;
