import ApplyFilters from './apply-filters';
import ClearFilters from './clear-filters';
import FilterByCheckbox from './filter-by-checkbox';
import FilterByText from './filter-by-text';
import SelectedFilter from './selected-filter/selected-filter';
import styles from './filters.module.scss';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useState } from 'react';

const Filters = ({
    isFilterOpen,
    setFilteredData,
    resultsData,
    filterFormSpecs,
    filteredData
}) => {
    const [search, setSearch] = useState({});

    const filter = (dataValue, searchValue) => {
        if (typeof dataValue === 'string') {
            return dataValue.toLowerCase() === searchValue.toLowerCase();
        }
        return !!dataValue?.includes(searchValue);
    }

    useDeepCompareEffect(() => {
        if (Object.keys(search).length > 0) {
            Object.keys(search).forEach(key => {
                let newData = [];
                search[key].forEach(item => {
                    const selectedRow =
                        filteredData?.length > 0
                            ? filteredData.filter(row => filter(row[key], item))
                            : resultsData.filter(row => filter(row[key], item));
                    
                    if (selectedRow.length > 0) {
                        newData = [...newData, ...selectedRow];
                    }
                });
                setFilteredData(newData);
            });
            return;
        }
        setFilteredData(undefined);
    }, [resultsData, search, setFilteredData]);

    return isFilterOpen ? (
        <div className={`${styles.filtersPanel} nhsuk-u-margin-right-4`}>
            <div className={`${styles.filtersHeader} nhsuk-u-padding-3`}>
                <p className="nhsuk-u-font-weight-bold nhsuk-u-margin-bottom-0">Filter</p>
            </div>
            <div className={`${styles.filtersSelected} nhsuk-u-padding-3`}>
                <h4 className="nhsuk-heading-s">Selected filters</h4>
                <div className={styles.filtersClearFilter}>
                    <ClearFilters setSearch={setSearch} />
                </div>
                <div className="nhsuk-u-margin-top-2">
                    <SelectedFilter
                        search={search}
                        clearSearch={setSearch}
                        filterFormSpecs={filterFormSpecs}
                    />
                </div>
            </div>
            <div className={`${styles.filters} nhsuk-u-padding-3`}>
                <ApplyFilters
                    setSearch={setSearch}
                    search={search}
                    filterFormSpecs={filterFormSpecs}
                />
                <FilterByText filterFormSpecs={filterFormSpecs} />
                <FilterByCheckbox filterFormSpecs={filterFormSpecs} />
            </div>
        </div>
    ) : null;
};

export default Filters;
