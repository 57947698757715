export interface lookUpStatus {
    status: string,
    message?: string
}

export const lookUpRequestStatus = {
    failed: {
        status: 'failed',
        message: 'No user was found with that short code'
    },
    rejected: {
        status: 'rejected',
        message: 'You must enter a valid short code'
    },
    success: {
        status: 'success',
        message: undefined
    }
}
