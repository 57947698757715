import { useEffect, useState } from 'react';

import { useLazyGetOrgQuery } from "../../../services/ods.api";

export const useNumberOfLinks = (initialValue: number | undefined = undefined) => {
    const [numberOfLinks, setNumberOfLinks] = useState<number | undefined>(initialValue);
    const [numberOfLinksError, setNumberOfLinksError] = useState<string>("");

    useEffect(() => {
        if (numberOfLinks?.toString().match(/[1-9]/)) {
            setNumberOfLinksError("")
        }
    }, [numberOfLinks]);

    const numberOfLinksChange = (numberOfLinks: number) => setNumberOfLinks(numberOfLinks);

    const isNumberOfLinksValid = (): boolean => {
        if (!numberOfLinks?.toString().match(/[1-9]/)) {
            setNumberOfLinksError("Please provide the number of links required");
            return false;
        }
        return true;
    }

    return [
        numberOfLinks,
        numberOfLinksError,
        numberOfLinksChange,
        isNumberOfLinksValid
    ] as const;
}

export const useCollection = (initialValue: string = "") => {
    const [collection, setCollection] = useState<string>(initialValue);
    const [collectionError, setCollectionError] = useState<string>("");

    useEffect(() => {
        if (collection !== "") {
            setCollectionError("");
        }
    }, [collection]);

    const setCollectionId = (id: string) => setCollection(id);

    const isCollectionValid = (): boolean => {
        if (collection === "") {
            setCollectionError("Please select a collection from the drop down");
            return false;
        }
        return true;
    }

    return [
        collection,
        collectionError,
        setCollectionId,
        isCollectionValid
    ] as const;
}

export const useOrgCode = (initialValue: string = "") => {
    const [orgCode, setOrgCode] = useState<string>(initialValue);
    const [orgCodeError, setOrgCodeError] = useState<string>(initialValue);
    const [trigger, { currentData: orgCodeData, isError }] = useLazyGetOrgQuery();

    useEffect(() => {
        if (typeof orgCodeData === 'undefined' && isError) {
            setOrgCodeError(
              "The Organisation Code you have entered does not exist, please check and retry."
            );
        }
        if (typeof orgCodeData !== 'undefined') {
            setOrgCodeError("");
        }
    }, [orgCode, orgCodeData, isError]);

    const handleOrgCodeBlur = (): void => {
        trigger(orgCode);
    }

    const handleOrgCodeChange = (code: string) => setOrgCode(code);

    const isOrgCodeValid = (): boolean => {
        if (orgCode === "") {
            setOrgCodeError("Please provide a valid organisation code");
            return false;
        }
        return true;
    }

    return [
        orgCode,
        orgCodeError,
        handleOrgCodeBlur,
        handleOrgCodeChange,
        isOrgCodeValid
    ] as const;
}
