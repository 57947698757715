import { UserRole } from './user-role';

export interface ConsoleUser {
    aad_upn: string;
    created_at: string;
    created_by: string;
    created_by_sub: string;
    deleted: boolean;
    email_verified: boolean;
    email: string;
    enabled: boolean;
    first_name: string;
    idp: IdentityProvider;
    short_code?: string;
    sort_key: string;
    sub: string;
    surname: string;
    updated_at?: string;
    updated_by?: string;
    updated_by_sub?: string;
    username: string;
}

export enum IdentityProvider {
    Aad = 'aad'
}

export interface Role {
    id: UserRole;
    collection_id?: string;
}
