import { BodyText, SummaryList } from 'nhsuk-react-components';
import { ImportStatus } from '@forms/console-types/api-responses';

interface ImportFileInfoProps {
    data: ImportStatus | undefined;
}

export function ImportFileInfo(props: ImportFileInfoProps) {
    return (
        <>
          <SummaryList>
            <SummaryList.Row>
            <SummaryList.Key>File Name</SummaryList.Key>
                    <SummaryList.Value>{props.data?.file_name || 'Loading...'}</SummaryList.Value>
            </SummaryList.Row>
            <SummaryList.Row>
            <SummaryList.Key>Processing Started</SummaryList.Key>
                    <SummaryList.Value>{props.data?.processing_started_at || 'Loading...'}</SummaryList.Value>
            </SummaryList.Row>
            <SummaryList.Row>
            <SummaryList.Key>Processing Finished</SummaryList.Key>
            <SummaryList.Value>{props.data?.processing_completed_at || 'Loading...'}</SummaryList.Value>
            </SummaryList.Row>
            <SummaryList.Row>
            <SummaryList.Key>Status</SummaryList.Key>
            <SummaryList.Value>
                        <BodyText>{props.data?.status || 'Loading...'}</BodyText>
            </SummaryList.Value>
            </SummaryList.Row>
        </SummaryList>
        </>
    )
}

export default ImportFileInfo;
