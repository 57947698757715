import { Card } from 'nhsuk-react-components';
import { Link } from 'react-router-dom';
import { Permission } from '@forms/console-types';
import React from 'react';
import { RenderIf } from '../../app/render-if/render-if';

export function HomeCards() {
    return (
        <>
            <Card.Group>
                <RenderIf hasPermission={Permission.ConsoleUserList}>
                    <Card.GroupItem width="one-half">
                        <Card clickable>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Link to="/users">Manage Console Users</Link>
                                </Card.Heading>
                                <Card.Description>
                                    Add, edit and deactivate management console users
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                </RenderIf>
                <RenderIf hasPermission={Permission.SubmitterUserList}>
                    <Card.GroupItem width="one-half">
                        <Card clickable>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Link to="/submitters">Manage Submitters</Link>
                                </Card.Heading>
                                <Card.Description>
                                    Add, edit and deactivate submitters
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                </RenderIf>
                <RenderIf hasPermission={Permission.DirectAccessLinksManage}>
                    <Card.GroupItem width="one-half">
                        <Card clickable>
                            <Card.Content>
                                <Card.Heading className="nhsuk-heading-m">
                                    <Link to="/direct-access-links">
                                        Manage Direct Access Links
                                    </Link>
                                </Card.Heading>
                                <Card.Description>
                                    Create and export Direct Access Links
                                </Card.Description>
                            </Card.Content>
                        </Card>
                    </Card.GroupItem>
                </RenderIf>
            </Card.Group>
        </>
    );
}
export default HomeCards;
