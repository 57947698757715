import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery
} from "@reduxjs/toolkit/query/react";

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
    const baseQuery = fetchBaseQuery({
        baseUrl: 'https://directory.spineservices.nhs.uk/ORD/2-0-0/'
    });
    return baseQuery(args, api, extraOptions);
};

export const odsApi = createApi({
    baseQuery,
    reducerPath: 'odsApi',
    endpoints: build => ({
        getOrg: build.query({
            query: orgCode => `organisations/${orgCode}`,
            keepUnusedDataFor: 0,
        })
    })
});

export const { useLazyGetOrgQuery } = odsApi;
