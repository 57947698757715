import { HomeCards } from '../home-cards/home-cards';
import { NoAccessCard } from '@home';
import { Permission } from '@forms/console-types';
import { selectAuthState } from 'store/auth.slice';
import { useAppSelector } from 'app/hooks';
import { useBreadcrumbs } from '@app';
import { setTitle } from 'util/browser';

export function HomePage() {
    setTitle('Home');
    useBreadcrumbs([]);

    const { permissions } = useAppSelector(selectAuthState);
    const hasPermission = [Permission.ConsoleUserList, Permission.SubmitterUserList];

    if (permissions.some(item => hasPermission.indexOf(item) >= 0)) {
        return (
            <>
                <h1>DPS Forms Management Console</h1>
                <HomeCards />
            </>
        );
    } else {
        return (
            <>
                <h1>DPS Forms Management Console</h1>
                <NoAccessCard />
            </>
        );
    }
}

export default HomePage;
