import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Permission } from '@forms/console-types';
import { selectAuthState } from 'store/auth.slice';
import { useAppSelector } from 'app/hooks';

export interface PrivateRouteProps extends RouteProps {
    hasPermission?: Permission;
    children?: ReactElement;
    props?: RouteProps;
}

export default function PrivateRoute({ children, hasPermission, ...props }: PrivateRouteProps) {
    const { isLoggedIn, permissions } = useAppSelector(selectAuthState);

    if (!isLoggedIn) {
        return <Redirect to="/login" />;
    }

    if (hasPermission) {
        if (permissions.includes(hasPermission)) {
            return <Route {...props}>{children}</Route>;
        } else {
            return <Redirect to="/login" />;
        }
    }

    return <Route {...props}>{children}</Route>;
}
