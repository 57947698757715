import { Footer } from 'nhsuk-react-components';
import React from 'react';

const AppFooter = () => (
    <Footer>
        <Footer.List>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital">About</Footer.ListItem>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/privacy-and-cookies">
                About Privacy and cookies
            </Footer.ListItem>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/terms-and-conditions">
                Terms and conditions
            </Footer.ListItem>
            <Footer.ListItem href="https://digital.nhs.uk/about-nhs-digital/accessibility">
                Accessibility
            </Footer.ListItem>
        </Footer.List>
        <Footer.Copyright>&copy; Crown copyright</Footer.Copyright>
    </Footer>
);

export default AppFooter;
