import { useEffect, useState } from 'react';

import ErrorNotice from './error-notice/error-notice';
import ImportFileErrorInfo from './import-file-info/import-file-error-info';
import ImportFileInfo from './import-file-info/import-file-info';
import { ImportMetaDataStatus } from '@forms/console-types';
import ProcessingCard from './processing-card/processing-card';
import { setTitle } from 'util/browser';
import { useBreadcrumbs } from 'components/app';
import { useGetImportDetailsQuery } from 'services/console.api';
import { useParams } from 'react-router-dom';

type importId = {
  id: string;
};

export function SubmittersImportFeedbackPage() {
    setTitle('Import Submitters Feedback');
    useBreadcrumbs([
        {
            display: 'Submitters',
            path: '/submitters'
        },
        {
            display: 'Import Submitters',
            path: '/submitters/import'
        },
        {
            display: 'Import Feedback',
            path: '#'
        }
    ]);

    const [pollingTime, setPollingTime] = useState<number>(5000);
    const { id: importId } = useParams<importId>();
    const { data: importData } = useGetImportDetailsQuery(importId, { pollingInterval: pollingTime });
    

    useEffect(() => { 
        if (importData?.status === ImportMetaDataStatus.Failed ||
            importData?.status === ImportMetaDataStatus.Complete ||
            importData?.status === ImportMetaDataStatus.CompleteWithErrors) {
            setPollingTime(0);
        }
    }, [importData]);

    
    return (
        <>
            <h1>Import Feedback</h1>
            {(importData?.status === 'queued' || importData?.status === 'processing') &&
                <ProcessingCard />}
            {(importData?.status === 'complete-with-errors') &&
            <ErrorNotice />}
            < ImportFileInfo data={importData} />
            {(importData?.errors) && (importData?.status === ImportMetaDataStatus.CompleteWithErrors ||
                importData?.status === ImportMetaDataStatus.Failed ) && <ImportFileErrorInfo data={importData} />}
        </>
    );
}
