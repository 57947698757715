import { Button, useBreadcrumbs } from 'components/app';
import { Input, Label, Select } from 'nhsuk-react-components';
import React, { useEffect } from 'react';
import { useCollection, useNumberOfLinks, useOrgCode } from './direct-access-links-create.hooks';
import { useGetCollectionsQuery, usePostDirectAccessLinksMutation } from 'services/console.api';

import { PostDirectAccessLinksApiRequest } from '@forms/console-types/api-requests';
import { WarningCallout } from 'nhsuk-react-components';
import styles from './direct-access-links-create-page.module.scss';
import { useHistory } from 'react-router-dom';
import { setTitle } from 'util/browser';

export const DirectAccessLinksCreatePage = () => {
    setTitle('Create Direct Access Links');
    useBreadcrumbs([
        {
            display: 'Direct Access Links',
            path: '/direct-access-links'
        },
        {
            display: 'Create Direct Access Links',
            path: '/direct-access-links/create'
        }
    ]);

    const history = useHistory();
    const [numberOfLinks, numberOfLinksError, setNumberOfLinks, isNumberOfLinksValid] =
        useNumberOfLinks();
    const [collection, collectionError, setCollection, isCollectionValid] = useCollection();
    const [orgCode, orgCodeError, handleOrgCodeBlur, setOrgCode, isOrgCodeValid] = useOrgCode();
    const { data, isSuccess: isCollectionRequestSuccess } = useGetCollectionsQuery();
    const [postDirectAccessLinks, { isSuccess: isSuccessCreateDirectAccessLinks }] =
        usePostDirectAccessLinksMutation();

    useEffect(() => {
        if (isSuccessCreateDirectAccessLinks) history.push('/direct-access-links');
    }, [isSuccessCreateDirectAccessLinks, history]);

    const handleLinksChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        evt.target && setNumberOfLinks(parseInt(evt.target.value));
    };

    const handleSelectChange = (evt: React.ChangeEvent<HTMLSelectElement>): void => {
        evt.target && setCollection(evt.target.value);
    };

    const handleOrgCodeChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        if (evt.target) {
            if (evt.target.value.match(/[a-z]/)) {
                setOrgCode(evt.target.value.toUpperCase());
                return;
            }
            setOrgCode(evt.target.value);
        }
    };

    const handleSubmit = (evt: React.MouseEvent): void => {
        evt.preventDefault();
        const validNumberOfLinks = isNumberOfLinksValid();
        const validCollection = isCollectionValid();
        const validOrgCode = isOrgCodeValid();

        if (validNumberOfLinks && validCollection && validOrgCode && numberOfLinks !== undefined) {
            const request: PostDirectAccessLinksApiRequest = {
                collection_id: collection,
                link_count: numberOfLinks,
                org_code: orgCode
            };
            postDirectAccessLinks(request);
        }
    };

    return (
        <>
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-three-quarters">
                    <h1>Create Direct Access Links</h1>
                    <WarningCallout>
                        <WarningCallout.Label>Organisation Code</WarningCallout.Label>
                        <p>
                            You can find Organisation Codes using the 'Organisation Search' facility
                            on the{' '}
                            <a
                                href="https://odsportal.digital.nhs.uk/Organisation/Search"
                                target="_BLANK"
                                rel="noreferrer"
                            >
                                NHS ODS website
                            </a>
                        </p>
                    </WarningCallout>
                    <Label htmlFor="number-of-links">No. links Required</Label>
                    <Input
                        className={styles.select}
                        id="number-of-links"
                        type="number"
                        onChange={handleLinksChange}
                        value={numberOfLinks}
                        error={numberOfLinksError}
                    />
                    <Select
                        error={collectionError}
                        id="collection-name-selection"
                        label="Collection Name"
                        className={styles.select}
                        onChange={handleSelectChange}
                    >
                        <Select.Option value="">Please select a collection</Select.Option>
                        {isCollectionRequestSuccess &&
                            data?.collections
                                .filter(collection =>
                                    collection.identity_providers?.includes('dal')
                                )
                                .map(
                                    (collection): JSX.Element => (
                                        <Select.Option key={collection.id} value={collection.id}>
                                            {collection.name}
                                        </Select.Option>
                                    )
                                )}
                    </Select>
                    <Label htmlFor="org-code">Organisation Code</Label>
                    <Input
                        className={styles.select}
                        id="org-code"
                        type="text"
                        onBlur={handleOrgCodeBlur}
                        onChange={handleOrgCodeChange}
                        value={orgCode}
                        error={orgCodeError}
                    />
                    <div className={styles.createDalLinksButtons}>
                        <Button onClick={handleSubmit}>Add</Button>
                        <Button secondary to="/direct-access-links">
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};
