import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { consoleApi, odsApi } from '../services';

import authReducer from 'store/auth.slice';
import breadcrumbsReducer from 'store/bread-crumbs.slice';
import configReducer from 'store/config.slice';
import errorReducer from 'store/error.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        breadcrumbs: breadcrumbsReducer,
        config: configReducer,
        error: errorReducer,
        [consoleApi.reducerPath]: consoleApi.reducer,
        [odsApi.reducerPath]: odsApi.reducer
    },
    middleware: gDM => gDM().concat(consoleApi.middleware, odsApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
