import { lookUpRequestStatus, lookUpStatus } from "./add-user-page.types";
import { useEffect, useState } from 'react';

import { useCallback } from 'react';
import { useLazyGetAadUserDetailsQuery } from "../../../services/console.api";

export function useShortCodeLookUp() {
    const [lookUpStatus, setLookUpStatus] = useState<lookUpStatus>({ status: 'uninitialized' });
    const [trigger, result] = useLazyGetAadUserDetailsQuery();
    const { isSuccess, data: userDetails } = result;

    const validateHookStatus = useCallback(
      (): void => {
          if (isSuccess && userDetails === undefined) {
              setLookUpStatus(lookUpRequestStatus.failed)
          }
          if (isSuccess && userDetails) {
              setLookUpStatus(lookUpRequestStatus.success)
          }
      },
      [isSuccess, userDetails],
    );

    useEffect(() => {
        validateHookStatus();
    }, [isSuccess, validateHookStatus])

    const userLookUp = (shortCode: string): void => {
        if (shortCode === undefined || shortCode === "") {
            setLookUpStatus(lookUpRequestStatus.rejected)
            return;
        }
        trigger(shortCode);
    }

    return [userLookUp, lookUpStatus, setLookUpStatus, userDetails] as const;
}
