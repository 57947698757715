import React from 'react';
import styles from './pagination-results.module.scss';

const PaginationResults = ({ pageStartIndex, pageEndIndex, numberOfResults }) => {
    return (
        <p>
            Showing
            <span className={styles.highlight}>{pageStartIndex}</span>
            to
            <span className={styles.highlight}>{pageEndIndex}</span>
            of
            <span className={styles.highlight}>{numberOfResults}</span>
            results
        </p>
    );
};

export default PaginationResults;
