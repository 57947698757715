export interface ImportMetaData {
    created_at: string;
    created_by: string;
    expires: number;
    file_name: string;
    pk: string;
    sk: string;
    status: ImportMetaDataStatus;
    type: string;
    updated_at: string;
    processing_completed_at?: string;
    processing_started_at?: string;
    error_count?: number;
}

export interface ImportMetaDataError {
    created_at: string;
    error_reason: ImportMetaDataErrorReason;
    expires: number;
    pk: string;
    row_number: number;
    sk: string;
}

export enum ImportMetaDataStatus {
    Complete = 'complete',
    CompleteWithErrors = 'complete-with-errors',
    Failed = 'failed',
    Processing = 'processing',
    Queued = 'queued'
}

export enum ImportMetaDataErrorReason {
    Duplicate = 'duplicate',
    InvalidCollection = 'invalid-collection',
    InvalidEmail = 'invalid-email',
    InvalidOrg = 'invalid-org',
    MissingField = 'missing-field'
}
