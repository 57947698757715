import { Permission } from '@forms/console-types';
import React from 'react';
import { selectAuthState } from '../../../store/auth.slice';
import { useAppSelector } from '../../../app/hooks';

interface RenderIfProps {
    hasPermission?: Permission;
}

export const RenderIf: React.FC<RenderIfProps> = ({ hasPermission, children }) => {
    const auth = useAppSelector(selectAuthState);

    if (hasPermission) {
        return auth.permissions.includes(hasPermission) ? <>{children}</> : null;
    }

    return <>{children}</>;
};
