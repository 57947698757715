const FiltersToggleButton = ({ isFilterOpen, setIsFilterOpen }) => {
    const onClick = event => {
        event.preventDefault();
        event.target.blur();
        setIsFilterOpen(!isFilterOpen);
    };

    return (
        <button onClick={onClick} className="nhsuk-button nhsuk-button--reverse" type="submit">
            {isFilterOpen === true ? ' Hide Filter' : 'Show Filter'}
        </button>
    );
};

export default FiltersToggleButton;
