import { Card } from 'nhsuk-react-components';

export function ProcessingCard() {
    return (
        <>
        <Card.Group>
            <Card.GroupItem width="one-half">
                <Card feature>
                    <Card.Content>
                        <Card.Heading className="nhsuk-heading-m">
                            Your file is queued for processing
                        </Card.Heading>
                        <Card.Description>
                            The information below will update once processing starts.
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Card.GroupItem>
        </Card.Group>
        </>
    )
}

export default ProcessingCard;
