import React from 'react';
import { useBreadcrumbs } from '@app';
import { setTitle } from 'util/browser';

const NotFoundPage = () => {
    setTitle('Page Not Found');
    useBreadcrumbs([]);

    return (
        <>
            <h1>Page Not Found</h1>
        </>
    );
};

export default NotFoundPage;
