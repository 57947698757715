import {
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
    createApi,
    fetchBaseQuery
} from '@reduxjs/toolkit/query/react';
import {
    DeleteSubmitterPermissionApiResponse,
    GetConsoleUsersApiResponse,
    GetDirectAccessLinksApiResponse,
    GetImportStatusApiResponse,
    GetSubmitterApiResponse,
    GetSubmittersApiResponse,
    ImportStatus,
    PostDirectAccessLinksApiResponse,
    PostSubmittersApiResponse,
    PostUserApiResponse
} from '@forms/console-types/api-responses';

import { DeleteSubmitterPermissionApiRequest } from '@forms/console-types/api-requests/delete-submitter-permission';
import { DirectAccessLinkBatch } from '@forms/console-types/api-responses/get-direct-access-links';
import { GetAadSearchApiResponse } from '@forms/console-types/api-responses/get-aad-search';
import { GetCollectionsApiResponse } from '@forms/console-types/api-responses/get-collections';
import { RootState } from 'app/store';
import { Submitter } from '@forms/console-types/api-responses/get-submitters';
import { logApiError } from 'store/error.slice';

const baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const state = api.getState() as RootState;

    const baseQuery = fetchBaseQuery({
        baseUrl: `${state.config.apiBaseUrl}/v1`,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState;
            const token = state.auth.accessToken;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        }
    });

    const result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status !== 401) {
        api.dispatch(logApiError('api request failed'));
    }

    return result;
};

export const consoleApi = createApi({
    baseQuery,
    reducerPath: 'consoleApi',
    tagTypes: ['Submitter'],
    endpoints: build => ({
        getDirectAccessLinks: build.query<DirectAccessLinkBatch[], void>({
            query: () => 'direct-access-links',
            keepUnusedDataFor: 0,
            transformResponse: (response: GetDirectAccessLinksApiResponse) =>
                response.direct_access_links
        }),
        postDirectAccessLinks: build.mutation({
            query: body => ({
                url: 'direct-access-links',
                method: 'POST',
                body
            }),
            transformResponse: (response: PostDirectAccessLinksApiResponse) => response.id
        }),
        getSubmitters: build.query<Submitter[], void>({
            query: () => 'submitters',
            keepUnusedDataFor: 0,
            providesTags: ['Submitter'],
            transformResponse: (response: GetSubmittersApiResponse) => response.submitters
        }),
        getSubmitter: build.query<GetSubmitterApiResponse, string>({
            query: sub => `submitters/${sub}`,
            keepUnusedDataFor: 0,
            providesTags: ['Submitter']
        }),
        deleteSubmitterPermissions: build.mutation({
            query: data => {
                const { sub, collectionId, orgCode } = data;

                const body: DeleteSubmitterPermissionApiRequest = {
                    collection_id: collectionId,
                    org_code: orgCode
                };

                return {
                    url: `submitters/${sub}/permissions`,
                    method: 'DELETE',
                    body
                };
            },
            invalidatesTags: ['Submitter'],
            transformResponse: (response: DeleteSubmitterPermissionApiResponse) => response.sub
        }),
        postSubmitters: build.mutation({
            query: body => ({
                url: 'submitters/import',
                method: 'POST',
                body
            }),
            invalidatesTags: ['Submitter'],
            transformResponse: (response: PostSubmittersApiResponse) => response.id
        }),
        getAadUserDetails: build.query<GetAadSearchApiResponse, string>({
            query: shortCode => `auth/aad/search?short-code=${shortCode}`,
            keepUnusedDataFor: 0,
            transformResponse: (response: GetAadSearchApiResponse[]) => response[0]
        }),
        getCollections: build.query<GetCollectionsApiResponse, void>({
            query: () => 'collections',
            keepUnusedDataFor: 3600
        }),
        postUser: build.mutation({
            query: body => ({
                url: 'users',
                method: 'POST',
                body
            }),
            transformResponse: (response: PostUserApiResponse) => response.sub
        }),
        getUsers: build.query<GetConsoleUsersApiResponse, void>({
            query: () => 'users',
            keepUnusedDataFor: 0
        }),
        getImportStatus: build.query<GetImportStatusApiResponse, string>({
            query: id => `submitters/import/${id}/status`,
            keepUnusedDataFor: 0,
            transformResponse: (response: GetImportStatusApiResponse) => response
        }),
        getImportDetails: build.query<ImportStatus, string>({
            query: id => `submitters/import/${id}`,
            keepUnusedDataFor: 0,
            transformResponse: (response: ImportStatus) => response
        })
    })
});

export const {
    useLazyGetImportStatusQuery,
    useGetImportDetailsQuery,
    useDeleteSubmitterPermissionsMutation,
    useGetCollectionsQuery,
    useGetDirectAccessLinksQuery,
    useGetSubmitterQuery,
    useGetSubmittersQuery,
    useGetUsersQuery,
    useLazyGetAadUserDetailsQuery,
    usePostDirectAccessLinksMutation,
    usePostSubmittersMutation,
    usePostUserMutation
} = consoleApi;
