import { AppTable, Link, useBreadcrumbs } from '@app';
import { useDeleteSubmitterPermissionsMutation, useGetSubmitterQuery } from 'services/console.api';
import { useEffect, useState } from 'react';

import { IdentityProvider } from '@forms/console-types/submitter';
import { Permission } from '@forms/console-types';
import { RenderIf } from 'components/app/render-if/render-if';
import { SortHeadSpecs } from 'components/app/table/sort-head/sort-head.interface';
import { SubmitterIdpOutput } from '../submitters-page/submitter-idp-output';
import { SubmitterPermission } from '@forms/console-types/api-responses/get-submitter';
import { SummaryList } from 'nhsuk-react-components';
import { selectAuthState } from 'store/auth.slice';
import { setTitle } from 'util/browser';
import { useAppSelector } from 'app/hooks';
import { useParams } from 'react-router-dom';

interface ViewSubmitterParams {
    sub: string;
}

export function ViewSubmitterPage() {
    const { sub } = useParams<ViewSubmitterParams>();
    const { data, isSuccess } = useGetSubmitterQuery(sub);
    const [deleteSubmitterPermissions] = useDeleteSubmitterPermissionsMutation();

    const { permissions } = useAppSelector(selectAuthState);
    const isEditEnabled = permissions.includes(Permission.SubmitterUserEdit);

    setTitle(data?.name ?? '');
    useBreadcrumbs([
        {
            display: 'Submitters',
            path: '/submitters'
        },
        {
            display: data?.name ?? '',
            path: `/submitters/${sub}`
        }
    ]);

    const [resultsData, setResultsData] = useState(data?.permissions ?? []);
    const [resultsPaginatedData, setResultsPaginatedData] = useState<SubmitterPermission[]>([]);

    useEffect(() => {
        (async function () {
            if (isSuccess && data) {
                setResultsData(data.permissions);
            }
        })();
    }, [setResultsData, isSuccess, data, resultsData]);

    const sortHeadSpecs: SortHeadSpecs[] = [
        { id: 'collection_name', display: 'Collection' },
        { id: 'org_name', display: 'Organisation' }
    ];

    if (isEditEnabled) {
        sortHeadSpecs.push({ id: 'actions', display: 'Actions' });
    }

    const handleRemovePermission = (
        event: React.MouseEvent<HTMLAnchorElement>,
        collectionId: string,
        orgCode: string
    ) => {
        event.preventDefault();

        deleteSubmitterPermissions({ sub, collectionId, orgCode });
    };

    if (isSuccess && data) {
        return (
            <>
                <h1>{data.name}</h1>

                <SummaryList>
                    <SummaryList.Row>
                        <SummaryList.Key>Identity Provider</SummaryList.Key>
                        <SummaryList.Value>
                            <SubmitterIdpOutput idp={data.idp} />
                        </SummaryList.Value>
                        <SummaryList.Actions />
                    </SummaryList.Row>
                    {data.idp === IdentityProvider.CIS2 && (
                        <SummaryList.Row>
                            <SummaryList.Key>CIS2 UUID</SummaryList.Key>
                            <SummaryList.Value>{data.cis2_sub}</SummaryList.Value>
                            <SummaryList.Actions />
                        </SummaryList.Row>
                    )}
                    <SummaryList.Row>
                        <SummaryList.Key>Email</SummaryList.Key>
                        <SummaryList.Value>{data.email}</SummaryList.Value>
                        <SummaryList.Actions />
                    </SummaryList.Row>
                    <SummaryList.Row>
                        <SummaryList.Key>Full Name</SummaryList.Key>
                        <SummaryList.Value>{data.name}</SummaryList.Value>
                        <SummaryList.Actions />
                    </SummaryList.Row>
                    <SummaryList.Row>
                        <SummaryList.Key>Enabled</SummaryList.Key>
                        <SummaryList.Value>{data.enabled ? 'Yes' : 'No'}</SummaryList.Value>
                        <SummaryList.Actions>
                            <RenderIf hasPermission={Permission.SubmitterUserEdit}>
                                <Link to={`/submitters/${sub}/manage`}>Change</Link>
                            </RenderIf>
                        </SummaryList.Actions>
                    </SummaryList.Row>
                </SummaryList>

                <h2>Collections / Organisations</h2>

                <AppTable.Container>
                    <AppTable>
                        <AppTable.SortHead
                            sortHeadSpecs={sortHeadSpecs}
                            setResultsData={setResultsData}
                            resultsData={resultsData}
                            initialSortDirection="asc"
                            initialSortBy="collection_name"
                        />
                        <AppTable.Body>
                            {resultsPaginatedData.map(
                                ({ collection_id, collection_name, org_code, org_name }) => (
                                    <AppTable.Row key={`${collection_id}_${org_code}`}>
                                        <AppTable.Cell>{collection_name}</AppTable.Cell>
                                        <AppTable.Cell>{org_name}</AppTable.Cell>
                                        {isEditEnabled && (
                                            <AppTable.Cell>
                                                <Link
                                                    to={`/submitters/${sub}#permissions/${collection_id}/${org_code}`}
                                                    onClick={e =>
                                                        handleRemovePermission(
                                                            e,
                                                            collection_id,
                                                            org_code
                                                        )
                                                    }
                                                >
                                                    Remove
                                                </Link>
                                            </AppTable.Cell>
                                        )}
                                    </AppTable.Row>
                                )
                            )}
                        </AppTable.Body>
                    </AppTable>
                    <AppTable.Pagination
                        resultsData={resultsData}
                        setResultsPaginatedData={setResultsPaginatedData}
                    />
                </AppTable.Container>
            </>
        );
    } else {
        return <>Loading...</>;
    }
}
