import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';

import { ErrorPage } from 'components/error';
import { Redirect } from 'react-router';
import { exchangeAuthCodeForTokens } from 'store/auth.slice';
import { getPermission } from 'store/auth.slice';
import { selectConfig } from 'store/config.slice';
import { useBreadcrumbs } from '@app';

enum Render {
    Initial,
    Loading,
    Error,
    Ok
}

export default function Oauth2CallbackPage() {
    useBreadcrumbs([]);

    const [render, setRender] = useState(Render.Initial);

    const dispatch = useAppDispatch();
    const config = useAppSelector(selectConfig);

    useEffect(() => {
        (async () => {
            if (render === Render.Initial) {
                const savedState = sessionStorage.getItem('pkce_state');
                const savedCodeVerifier = sessionStorage.getItem('code_verifier');
                const url = new URL(window.location.href);

                const code = url.searchParams.get('code');
                const state = url.searchParams.get('state');
                const error = url.searchParams.get('error');

                if (error === null && state === savedState && code && savedCodeVerifier) {
                    setRender(Render.Loading);

                    const result = await dispatch(
                        exchangeAuthCodeForTokens({
                            authCode: code,
                            authServerBaseUrl: config.auth.serverBaseUrl,
                            clientId: config.auth.clientId,
                            codeVerifier: savedCodeVerifier
                        })
                    );

                    if (result.meta.requestStatus === 'fulfilled') {
                        const permissionResult = await dispatch(getPermission());
                        if (permissionResult.meta.requestStatus === 'fulfilled') {
                            setRender(Render.Ok);
                        } else {
                            setRender(Render.Error);
                        }
                    } else {
                        setRender(Render.Error);
                    }
                } else {
                    setRender(Render.Error);
                }
            }
        })();
    }, [config, dispatch, render]);

    switch (render) {
        case Render.Ok:
            return <Redirect to="/" />;

        case Render.Error:
            return (
                <ErrorPage error_description="There was problem with your login attempt, Please try again later" />
            );

        default:
            return <>Loading...</>;
    }
}
