import { Table } from 'nhsuk-react-components';
import Container from './container/container';
import Pagination from './pagination/pagination';
import SortHead from './sort-head/sort-head';
import Filters from './filters/filters';
import FiltersToggleButton from './filters/filters-toggle-button';

// basic components from nhs
const AppTable = props => <Table {...props} />;
AppTable.Head = props => <Table.Head {...props} />;
AppTable.Body = props => <Table.Body {...props} />;
AppTable.Row = props => <Table.Row {...props} />;
AppTable.Cell = props => <Table.Cell {...props} />;

// the richer components
AppTable.Container = props => <Container {...props} />;
AppTable.Pagination = props => <Pagination {...props} />;
AppTable.SortHead = props => <SortHead {...props} />;
AppTable.Filters = props => <Filters {...props} />;
AppTable.FiltersToggleButton = props => <FiltersToggleButton {...props} />;

export default AppTable;
