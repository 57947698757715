export enum Permission {
    ConsoleUserList = 'ConsoleUser:List',
    ConsoleUserView = 'ConsoleUser:View',
    ConsoleUserCreate = 'ConsoleUser:Create',
    ConsoleUserEdit = 'ConsoleUser:Edit',
    SubmitterUserList = 'SubmitterUser:List',
    SubmitterUserView = 'SubmitterUser:View',
    SubmitterUserCreate = 'SubmitterUser:Create',
    SubmitterUserEdit = 'SubmitterUser:Edit',
    SubmitterUserImport = 'SubmitterUser:Import',
    CollectionList = 'Collection:List',
    CollectionView = 'DirectAccessLinks:Manage',
    DirectAccessLinksManage = 'Collection:View',
    CollectionViewOrgMetrics = 'Collection:ViewOrgMetrics',
    SubmitterUserListCollection = 'SubmitterUser:List:Collection',
    SubmitterUserViewCollection = 'SubmitterUser:View:Collection',
    SubmitterUserEditCollection = 'SubmitterUser:Edit:Collection',
    CollectionListCollection = 'Collection:List:Collection',
    CollectionViewCollection = 'Collection:View:Collection',
    CollectionViewOrgMetricsCollection = 'Collection:ViewOrgMetrics:Collection'
}
