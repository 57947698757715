import { Idp, selectConfig } from 'store/config.slice';
import React, { useEffect, useState } from 'react';

import { Button } from 'nhsuk-react-components';
import { getUrlSafeSHA256Hash } from 'util/crypto';
import { nanoid } from 'nanoid';
import { useAppSelector } from 'app/hooks';
import { useBreadcrumbs } from '@app';
import { setTitle } from 'util/browser';

export default function LoginPage() {
    setTitle('Login');
    useBreadcrumbs([]);

    const config = useAppSelector(selectConfig);
    const [authUrls, setAuthUrls] = useState(new Map<Idp, string>());

    useEffect(() => {
        (async () => {
            const authUrls = new Map<Idp, string>();

            const authUrl = new URL('oauth2/authorize', config.auth.serverBaseUrl);
            const redirectUrl = new URL('oauth2/callback', window.location.origin);
            const params = authUrl.searchParams;

            const state = nanoid(50);
            const codeVerifier = nanoid(50);
            const codeVerifierHash = await getUrlSafeSHA256Hash(codeVerifier);

            sessionStorage.setItem('pkce_state', state);
            sessionStorage.setItem('code_verifier', codeVerifier);

            params.set('response_type', 'code');
            params.set('client_id', config.auth.clientId);
            params.set('redirect_uri', redirectUrl.toString());
            params.set('state', state);
            params.set('code_challenge', codeVerifierHash);
            params.set('code_challenge_method', 'S256');

            for (const idp of config.auth.idps) {
                params.set('identity_provider', idp);
                authUrls.set(idp, authUrl.toString());
            }

            setAuthUrls(authUrls);
        })();
    }, [config]);

    if (authUrls) {
        return (
            <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-full">
                    <h1>Login</h1>
                    <p>You need to sign in to access this service.</p>

                    {authUrls.has(Idp.AzureAd) && (
                        <Button href={authUrls.get(Idp.AzureAd)} className="nhsuk-u-margin-right-3">
                            Login
                        </Button>
                    )}

                    {authUrls.has(Idp.Cognito) && (
                        <Button href={authUrls.get(Idp.Cognito)}>
                            Login with Username and Password
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    return null;
}
