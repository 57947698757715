export function isValidCsv (file: File) {
    const filenameArray = file.name.split('.');
    const fileExtension = filenameArray[filenameArray.length - 1];
    if (fileExtension === 'csv') {
        return true;
    }
    return false;
}

export function isFileTooLarge(file: File) {
    try {
        const fileSize = file.size;
        console.log(`File size: ${fileSize}`);
        return fileSize > 5242880 ? true : false;
    } catch (error) {
        return false;
    }
}
