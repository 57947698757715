import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export interface ErrorState {
    apiError: string | null
}

const initialState: ErrorState = {
    apiError: null
}

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        logApiError: (state, action: PayloadAction<string>) => {
            state.apiError = action.payload
        },
        clearApiError: (state) => {
            state.apiError = null
        }
    }
});


export const { logApiError, clearApiError } = errorSlice.actions

export const selectErrorState = (state: RootState) => state.error;

export default errorSlice.reducer;
