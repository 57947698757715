import { setTitle } from 'util/browser';
import { useBreadcrumbs } from '@app';
import { useGetSubmitterQuery } from 'services/console.api';
import { useParams } from 'react-router-dom';

interface ManageSubmitterParams {
    sub: string;
}

export function ManageSubmitterPage() {
    const { sub } = useParams<ManageSubmitterParams>();
    const { data, isSuccess } = useGetSubmitterQuery(sub);

    setTitle(data?.name ?? '');
    useBreadcrumbs([
        {
            display: 'Submitters',
            path: '/submitters'
        },
        {
            display: data?.name ?? '',
            path: `/submitters/${sub}`
        },
        {
            display: 'Manage',
            path: `/submitters/${sub}/manage`
        }
    ]);

    if (isSuccess && data) {
        return (
            <>
                <h1>{data.name}</h1>
            </>
        );
    } else {
        return <>Loading...</>;
    }
}
