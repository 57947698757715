import { Checkboxes } from 'nhsuk-react-components';

const FilterByCheckbox = ({ filterFormSpecs }) => {
    return filterFormSpecs
        .filter(formSpec => formSpec.type === 'radio' || formSpec.type === 'select')
        .map(formSpec => {
            return (
                <Checkboxes
                    question={formSpec.id}
                    id={formSpec.id}
                    label={formSpec.display}
                    key={formSpec.id}
                >
                    {formSpec.options.map((option, index) => (
                        <Checkboxes.Box key={index} value={option.key} onChange={() => {}}>
                            {option.value}
                        </Checkboxes.Box>
                    ))}
                </Checkboxes>
            );
        });
};

export default FilterByCheckbox;
