import { AppTable, Button, useBreadcrumbs } from '@app';
import React, { SyntheticEvent, useEffect, useState } from 'react';

import { DirectAccessLinkBatch } from '@forms/console-types/api-responses/get-direct-access-links';
import { FilterSpecs } from 'components/app/table/filters/filters.interface';
import { Permission } from '@forms/console-types';
import { SortHeadSpecs } from 'components/app/table/sort-head/sort-head.interface';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { selectAuthState } from 'store/auth.slice';
import { selectConfig } from 'store/config.slice';
import { useAppSelector } from 'app/hooks';
import { useGetDirectAccessLinksQuery } from 'services/console.api';
import { setTitle } from 'util/browser';

export function DirectAccessLinksPage() {
    setTitle('Direct Access Links');
    useBreadcrumbs([
        {
            display: 'Direct Access Links',
            path: '/direct-access-links'
        }
    ]);

    const { data, isSuccess } = useGetDirectAccessLinksQuery();
    const { apiBaseUrl } = useAppSelector(selectConfig);
    const { accessToken } = useAppSelector(selectAuthState);

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [resultsData, setResultsData] = useState(data ?? []);
    const [resultsPaginatedData, setResultsPaginatedData] = useState<DirectAccessLinkBatch[]>([]);
    const [filteredData, setFilteredData] = useState<DirectAccessLinkBatch[]>([]);

    useEffect(() => {
        (async function () {
            if (isSuccess && data) {
                setResultsData(data);
            }
        })();
    }, [setResultsData, isSuccess, data, resultsData]);

    const sortHeadSpecs: SortHeadSpecs[] = [
        { id: 'created_at', display: 'Created On' },
        { id: 'created_by', display: 'Created By' },
        { id: 'collection_name', display: 'Collection' },
        { id: 'org_code', display: 'Org' },
        { id: 'org_name', display: 'Org Name' },
        { id: 'link_count', display: 'No. Links' },
        { id: 'export_file', display: 'Export File' }
    ];

    const filterFormSpecs: FilterSpecs[] = [
        { id: 'created_by', display: 'Created By', type: 'text' },
        { id: 'collection_name', display: 'Collection', type: 'text' },
        { id: 'org_code', display: 'Org', type: 'text' },
        { id: 'org_name', display: 'Org Name', type: 'text' }
    ];

    const handleDownloadClick = (id: string) => async (event: SyntheticEvent) => {
        event.preventDefault();

        const url = new URL(`v1/direct-access-links/${id}/export`, apiBaseUrl).toString();

        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });

        const contentDispositionHeader = response.headers.get('Content-Disposition');
        const fileName = contentDispositionHeader?.split('filename=')[1];
        const blob = await response.blob();

        saveAs(blob, fileName);
    };

    if (isSuccess) {
        return (
            <>
                <h1>Direct Access Links</h1>

                <AppTable.Filters
                    isFilterOpen={isFilterOpen}
                    setFilteredData={setFilteredData}
                    filterFormSpecs={filterFormSpecs}
                    resultsData={resultsData}
                    filteredData={filteredData}
                />
                <AppTable.Container>
                    <Button
                        to="/direct-access-links/create"
                        permission={Permission.DirectAccessLinksManage}
                        className="nhsuk-u-margin-right-4"
                    >
                        Create Links
                    </Button>

                    <AppTable.FiltersToggleButton
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                    />
                    <AppTable>
                        <AppTable.SortHead
                            sortHeadSpecs={sortHeadSpecs}
                            setResultsData={setResultsData}
                            resultsData={resultsData}
                            initialSortDirection="desc"
                            initialSortBy="created_at"
                        />
                        <AppTable.Body>
                            {resultsPaginatedData.map((batch, i) => (
                                <AppTable.Row key={i}>
                                    <AppTable.Cell>
                                        {moment(batch.created_at).format('DD/MM/YY HH:mm:ss')}
                                    </AppTable.Cell>
                                    <AppTable.Cell>{batch.created_by}</AppTable.Cell>
                                    <AppTable.Cell>{batch.collection_name}</AppTable.Cell>
                                    <AppTable.Cell>{batch.org_code}</AppTable.Cell>
                                    <AppTable.Cell>{batch.org_name}</AppTable.Cell>
                                    <AppTable.Cell>{batch.link_count}</AppTable.Cell>
                                    <AppTable.Cell>
                                        <a
                                            href={`/direct-access-links#export-${batch.id}`}
                                            onClick={handleDownloadClick(batch.id)}
                                        >
                                            Download
                                        </a>
                                    </AppTable.Cell>
                                </AppTable.Row>
                            ))}
                        </AppTable.Body>
                    </AppTable>
                    <AppTable.Pagination
                        resultsData={filteredData === undefined ? resultsData : filteredData}
                        setResultsPaginatedData={setResultsPaginatedData}
                    />
                </AppTable.Container>
            </>
        );
    } else {
        return <>Loading...</>;
    }
}
