import React, { useEffect } from 'react';
import { fetchConfig, selectConfig } from 'store/config.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

interface ConfigProviderProps {
    children: React.ReactElement;
}

export default function ConfigProvider({ children }: ConfigProviderProps) {
    const { status } = useAppSelector(selectConfig);
    const dispatch = useAppDispatch();

    useEffect(
        () => {
            (async () => {
                if (status === 'initial') {
                    await dispatch(fetchConfig());
                }
            })();
        },
        [dispatch, status]
    );

    if (status === 'loaded') {
        return children;
    }

    return <>Loading...</>;
}
