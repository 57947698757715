import { ErrorSummary } from 'nhsuk-react-components';

export function ErrorNotice() {
    return (
        <>
        <ErrorSummary aria-labelledby="error-summary-title" role="alert" tabIndex={-1}>
            <ErrorSummary.Title id="error-summary-title">There is a problem</ErrorSummary.Title>
            <ErrorSummary.Body>
                    <p>The file you have selected for upload has errors.</p>
                    <p>Please review the identified issues below, remediate and try again.</p>
            </ErrorSummary.Body>
        </ErrorSummary>
        </>
    )
}


export default ErrorNotice;
