import { IdentityProvider } from '@forms/console-types/submitter';

export interface SubmitterIdpOutputProps {
    idp: IdentityProvider;
}

export function SubmitterIdpOutput(props: SubmitterIdpOutputProps) {
    switch (props.idp) {
        case IdentityProvider.CIS2:
            return <>CIS2</>;

        case IdentityProvider.Cognito:
            return <>DPS Forms</>;

        case IdentityProvider.SdcsClassicAuthN:
            return <>SDCS Classic</>;

        default:
            return <>{props.idp}</>;
    }
}
