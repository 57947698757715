import { Input } from 'nhsuk-react-components';

const FilterByText = ({ filterFormSpecs }) => {
    return filterFormSpecs
        .filter(formSpec => formSpec.type === 'text')
        .map(formSpec => {
            return (
                <Input
                    question={formSpec.id}
                    id={formSpec.id}
                    label={formSpec.display}
                    key={formSpec.id}
                />
            );
        });
};

export default FilterByText;
