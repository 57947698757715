import React, { useCallback, useEffect, useState } from 'react';

import PaginationNav from './pagination-nav';
import PaginationResults from './results/pagination-results';
import styles from './pagination.module.scss';

const Pagination = ({ resultsData, resultsPerPage = 25, setResultsPaginatedData }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isPaginationRequired, setIsPaginationRequired] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [pageEndIndex, setPageEndIndex] = useState(0);
    const [pageStartIndex, setPageStartIndex] = useState(0);

    const setPagingState = useCallback(
        setPageNum => {
            const resultsLength = resultsData?.length;
            const isPaginationRequired = resultsLength > resultsPerPage;
            let numPages, pageStartIndex, pageEndIndex;

            if (isPaginationRequired) {
                numPages = Math.ceil(resultsLength / resultsPerPage);

                if (typeof setPageNum === 'string') {
                    setPageNum = parseInt(setPageNum);
                }
                if (setPageNum > numPages) {
                    setPageNum = numPages;
                }

                if (setPageNum && setPageNum > 1) {
                    pageStartIndex = (setPageNum - 1) * resultsPerPage;
                    pageEndIndex = pageStartIndex + (resultsPerPage - 1);
                    if (pageEndIndex > resultsLength - 1) {
                        pageEndIndex = resultsLength - 1;
                    }
                } else {
                    setPageNum = 1;
                    pageStartIndex = 0;
                    pageEndIndex = resultsPerPage - 1;
                }
            } else {
                numPages = 0;
                pageStartIndex = 0;
                pageEndIndex = resultsLength - 1;
            }

            setCurrentPage(setPageNum);
            setIsPaginationRequired(isPaginationRequired);
            setNumPages(numPages);
            setPageEndIndex(pageEndIndex);
            setPageStartIndex(pageStartIndex);
            setResultsPaginatedData(resultsData.slice(pageStartIndex, pageEndIndex + 1));
        },
        [resultsData, resultsPerPage, setResultsPaginatedData]
    );

    useEffect(() => {
        setPagingState(1);
    }, [setPagingState]);

    return (
        <div className="nhsuk-grid-column-full">
            <div className="nhsuk-grid-row">
                {isPaginationRequired ? (
                    <div className={styles.Pagination}>
                        <PaginationNav
                            handlePageClick={setPagingState}
                            currentPage={currentPage}
                            numPages={numPages}
                        />
                    </div>
                ) : null}
                <div className={styles.PaginationResults}>
                    <PaginationResults
                        pageStartIndex={pageStartIndex + 1}
                        pageEndIndex={pageEndIndex + 1}
                        numberOfResults={resultsData.length}
                    />
                </div>
            </div>
        </div>
    );
};

export default Pagination;
