export interface Submitter {
    acr: string;
    amr: string;
    cis2_sub?: string;
    created_at: string;
    deleted: boolean;
    email_verified: boolean;
    email: string;
    enabled: boolean;
    idp: IdentityProvider;
    name: string;
    sdcs_classic_sub: string;
    sort_key: string;
    sub: string;
    updated_at: string;
    username: string;
}

export interface SubmitterPermission {
    sub: string;
    sort_key: string;
    name: string;
    org_code: string;
    org_name: string;
    org_type_code: string;
    org_type_description: string;
    url_slug: string;
}

export enum IdentityProvider {
    CIS2 = 'cis2',
    Cognito = 'cognito',
    DirectAccessLink = 'dal',
    SdcsClassicAuthN = 'sdcs-classic-authn',
    SdcsClassicAuthZ = 'sdcs-classic-authz'
}
