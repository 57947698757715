import { AppTable, Button, useBreadcrumbs } from '@app';
import { useEffect, useState } from 'react';

import { FilterSpecs } from 'components/app/table/filters/filters.interface';
import { Permission } from '@forms/console-types';
import { SortHeadSpecs } from 'components/app/table/sort-head/sort-head.interface';
import { User } from '@forms/console-types/api-responses/get-console-users';
import { setTitle } from 'util/browser';
import { useGetUsersQuery } from 'services/console.api';

export function ConsoleUsersPage() {
    setTitle('Console Users');
    useBreadcrumbs([
        {
            display: 'Console Users',
            path: '/users'
        }
    ]);

    const { data, isSuccess } = useGetUsersQuery();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [resultsData, setResultsData] = useState<User[]>([]);
    const [resultsPaginatedData, setResultsPaginatedData] = useState<User[]>([]);
    const [filteredData, setFilteredData] = useState<User[] | undefined>(undefined);

    useEffect(() => {
        (async function () {
            if (isSuccess && data?.users) {
                setResultsData(data.users);
            }
        })();
    }, [setResultsData, isSuccess, data]);

    const sortHeadSpecs: SortHeadSpecs[] = [
        { id: 'name', display: 'Name' },
        { id: 'short_code', display: 'Short Code' },
        { id: 'roles', display: 'Roles' },
        { id: 'email', display: 'Email' },
        { id: 'enabled', display: 'Enabled' }
    ];

    const filterFormSpecs: FilterSpecs[] = [
        { id: 'name', display: 'Name', type: 'text' },
        { id: 'short_code', display: 'Short Code', type: 'text' },
        { id: 'email', display: 'Email', type: 'text' },
        {
            id: 'roles',
            display: 'Roles',
            type: 'radio',
            options: [
                { key: 'admin', value: 'Admin' },
                { key: 'business', value: 'Business' },
                { key: 'console', value: 'Console' }
            ]
        }
    ];

    return isSuccess && data?.users ? (
        <>
            <h1>Console Users</h1>
            <AppTable.Filters
                isFilterOpen={isFilterOpen}
                setFilteredData={setFilteredData}
                filterFormSpecs={filterFormSpecs}
                resultsData={resultsData}
                filteredData={filteredData}
            />
            <AppTable.Container>
                <Button
                    to="/users/add"
                    permission={Permission.ConsoleUserCreate}
                    className="nhsuk-u-margin-right-4"
                >
                    Create New User
                </Button>

                <AppTable.FiltersToggleButton
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                />
                <AppTable>
                    <AppTable.SortHead
                        sortHeadSpecs={sortHeadSpecs}
                        setResultsData={setResultsData}
                        resultsData={resultsData}
                        initialSortDirection="asc"
                        initialSortBy="name"
                    />
                    <AppTable.Body>
                        {resultsPaginatedData.map(
                            ({ email, enabled, name, roles, short_code, sub }, i) => (
                                <AppTable.Row key={i}>
                                    <AppTable.Cell>
                                        {name} 
                                    </AppTable.Cell>
                                    <AppTable.Cell>{short_code}</AppTable.Cell>
                                    <AppTable.Cell>{roles.join(', ')}</AppTable.Cell>
                                    <AppTable.Cell>{email}</AppTable.Cell>
                                    <AppTable.Cell>{enabled ? 'Yes' : 'No'}</AppTable.Cell>
                                </AppTable.Row>
                            )
                        )}
                    </AppTable.Body>
                </AppTable>
                <AppTable.Pagination
                    resultsData={filteredData === undefined ? resultsData : filteredData}
                    setResultsPaginatedData={setResultsPaginatedData}
                />
            </AppTable.Container>
        </>
    ) : (
        <>Loading...</>
    );
}
