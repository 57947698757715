import { AppTable, Button, Link, useBreadcrumbs } from '@app';
import { useEffect, useState } from 'react';

import { FilterSpecs } from 'components/app/table/filters/filters.interface';
import { IdentityProvider } from '@forms/console-types/submitter';
import { Permission } from '@forms/console-types';
import { SortHeadSpecs } from 'components/app/table/sort-head/sort-head.interface';
import { Submitter } from '@forms/console-types/api-responses/get-submitters';
import { SubmitterIdpOutput } from './submitter-idp-output';
import moment from 'moment';
import { setTitle } from 'util/browser';
import { useGetSubmittersQuery } from 'services/console.api';

export function SubmittersPage() {
    setTitle('Submitters');
    useBreadcrumbs([
        {
            display: 'Submitters',
            path: '/submitters'
        }
    ]);

    const { data, isSuccess } = useGetSubmittersQuery();

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [resultsData, setResultsData] = useState(data ?? []);
    const [resultsPaginatedData, setResultsPaginatedData] = useState<Submitter[]>([]);
    const [filteredData, setFilteredData] = useState<Submitter[]>([]);

    useEffect(() => {
        (async function () {
            if (isSuccess && data) {
                setResultsData(data);
            }
        })();
    }, [setResultsData, isSuccess, data, resultsData]);

    const sortHeadSpecs: SortHeadSpecs[] = [
        { id: 'name', display: 'Name' },
        { id: 'email', display: 'Email' },
        { id: 'idp', display: 'Identity Provider' },
        { id: 'created_at', display: 'Created On' },
        { id: 'enabled', display: 'Status' }
    ];

    const filterFormSpecs: FilterSpecs[] = [
        { id: 'name', display: 'Name', type: 'text' },
        { id: 'email', display: 'Email', type: 'text' },
        {
            id: 'idp',
            display: 'Identity Provider',
            type: 'select',
            options: [
                { key: IdentityProvider.CIS2, value: 'CIS2' },
                { key: IdentityProvider.SdcsClassicAuthN, value: 'SDCS Classic' }
            ]
        }
    ];

    if (isSuccess) {
        return (
            <>
                <h1>Submitters</h1>

                <AppTable.Filters
                    isFilterOpen={isFilterOpen}
                    setFilteredData={setFilteredData}
                    filterFormSpecs={filterFormSpecs}
                    resultsData={resultsData}
                    filteredData={filteredData}
                />
                <AppTable.Container>
                    <Button
                        to="/submitters/import"
                        permission={Permission.SubmitterUserImport}
                        className="nhsuk-u-margin-right-4"
                    >
                        Import Submitters
                    </Button>

                    <AppTable.FiltersToggleButton
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                    />
                    <AppTable>
                        <AppTable.SortHead
                            sortHeadSpecs={sortHeadSpecs}
                            setResultsData={setResultsData}
                            resultsData={resultsData}
                            initialSortDirection="asc"
                            initialSortBy="name"
                        />
                        <AppTable.Body>
                            {resultsPaginatedData.map(
                                ({ email, name, idp, created_at, enabled, sub }) => (
                                    <AppTable.Row key={sub}>
                                        <AppTable.Cell>
                                            <Link
                                                to={`/submitters/${sub}`}
                                                permission={Permission.SubmitterUserView}
                                            >
                                                {name}
                                            </Link>
                                        </AppTable.Cell>
                                        <AppTable.Cell>{email}</AppTable.Cell>
                                        <AppTable.Cell>
                                            <SubmitterIdpOutput idp={idp} />
                                        </AppTable.Cell>
                                        <AppTable.Cell>
                                            {moment(created_at).format('DD/MM/YY HH:mm:ss')}
                                        </AppTable.Cell>
                                        <AppTable.Cell>
                                            {enabled ? 'Enabled' : 'Disabled'}
                                        </AppTable.Cell>
                                    </AppTable.Row>
                                )
                            )}
                        </AppTable.Body>
                    </AppTable>
                    <AppTable.Pagination
                        resultsData={filteredData === undefined ? resultsData : filteredData}
                        setResultsPaginatedData={setResultsPaginatedData}
                    />
                </AppTable.Container>
            </>
        );
    } else {
        return <>Loading...</>;
    }
}
