import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Permission } from '@forms/console-types';
import { RootState } from 'app/store';
import axios from 'axios';

export interface AuthState {
    accessToken: string | null;
    isExpired: boolean;
    isLoggedIn: boolean;
    permissions: Permission[];
    refreshToken: string | null;
}

const initialState: AuthState = {
    accessToken: null,
    isExpired: false,
    isLoggedIn: false,
    permissions: [],
    refreshToken: null
};

export interface ExchangeAuthCodeForTokensArgs {
    authCode: string;
    authServerBaseUrl: string;
    clientId: string;
    codeVerifier: string;
}

export const getPermission = createAsyncThunk(
    'auth/getPermission',
    async (_, thunkAPI): Promise<Permission[]> => {
        const state = thunkAPI.getState() as RootState;
        const response = await axios({
            method: 'get',
            url: `${state.config.apiBaseUrl}/v1/auth/permissions`,
            headers: {
                Authorization: `Bearer ${state.auth.accessToken}`
            }
        });
        return response.data.permissions;
    }
);

export const exchangeAuthCodeForTokens = createAsyncThunk(
    'auth/exchangeAuthCodeForTokens',
    async (args: ExchangeAuthCodeForTokensArgs) => {
        const tokenUrl = new URL('oauth2/token', args.authServerBaseUrl).toString();

        const params = new URLSearchParams({
            client_id: args.clientId,
            code_verifier: args.codeVerifier,
            code: args.authCode,
            grant_type: 'authorization_code',
            redirect_uri: new URL('oauth2/callback', window.location.origin).toString()
        });

        const response = await axios.post(tokenUrl, params);

        return response.data;
    }
);

export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const url = new URL('oauth2/token', state.config.auth.serverBaseUrl).toString();

        const params = new URLSearchParams({
            grant_type: 'refresh_token',
            client_id: state.config.auth.clientId,
            refresh_token: state.auth.refreshToken ?? ''
        });

        const response = await axios.post(url, params);

        return response.data;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(exchangeAuthCodeForTokens.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoggedIn = true;
                state.refreshToken = action.payload.refresh_token;
                state.permissions = [];
            })
            .addCase(exchangeAuthCodeForTokens.rejected, state => {
                state.accessToken = null;
                state.isLoggedIn = false;
                state.refreshToken = null;
                state.permissions = [];
            })
            .addCase(getPermission.fulfilled, (state, action: PayloadAction<Permission[]>) => {
                state.permissions = action.payload;
            })
            .addCase(getPermission.rejected, state => {
                state.permissions = [];
            })
            .addCase(refreshAccessToken.fulfilled, (state, action) => {
                state.accessToken = action.payload.access_token;
                state.isLoggedIn = true;
            })
            .addCase(refreshAccessToken.rejected, state => {
                if (state.isLoggedIn) {
                    state.isExpired = true;
                }
                state.accessToken = null;
                state.isLoggedIn = false;
            });
    }
});

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
