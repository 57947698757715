import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

export interface BreadcrumbState {
    breadcrumbs: Breadcrumb[];
}

export interface Breadcrumb {
    display: string;
    path: string;
}

const initialState: BreadcrumbState = {
    breadcrumbs: []
};

export const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {
        setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
            state.breadcrumbs = action.payload;
        }
    }
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export const selectBreadcrumbs = (state: RootState) => state.breadcrumbs.breadcrumbs;

export default breadcrumbsSlice.reducer;
