import React, { ComponentProps, MouseEvent } from 'react';

import { Button as NhsButton } from 'nhsuk-react-components';
import { Permission } from '@forms/console-types';
import { RenderIf } from '../render-if/render-if';
import { useHistory } from 'react-router-dom';

type ButtonProps = ComponentProps<typeof NhsButton> & {
    permission?: Permission;
    to?: string;
};

export const Button: React.FC<ButtonProps> = ({ children, onClick, permission, to, ...rest }) => {
    const history = useHistory();

    const handleClick = (event: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event);
        }

        if (to) {
            history.push(to);
        }
    };

    return (
        <RenderIf hasPermission={permission}>
            <NhsButton onClick={handleClick} {...rest}>
                {children}
            </NhsButton>
        </RenderIf>
    );
};
