import React, { useEffect, useState } from 'react';

import { AppTable } from '@app';
import { FilterSpecs } from 'components/app/table/filters/filters.interface';
import { ImportMetaDataErrorReason } from '@forms/console-types';
import { ImportStatus } from '@forms/console-types/api-responses';
import { ImportStatusError } from '@forms/console-types/api-responses/get-import';
import { SortHeadSpecs } from 'components/app/table/sort-head/sort-head.interface';

interface ImportFileInfoProps {
    data: ImportStatus | undefined;
}

export function ImportFileInfo(props: ImportFileInfoProps) {
    const errorReasonEasyRead = {
        'duplicate': 'User association already exists',
        'invalid-collection' : 'Collection does not exist',
        'invalid-email' : 'Invalid email',
        'invalid-org' : 'Organisation does not exist in the ODS or it is closed',
        'missing-field' : 'Missing mandatory field(s)'
    }

    const errorReasonRemediation = {
        'duplicate': 'Amend or remove row from file',
        'invalid-collection' : 'Amend or remove row from file',
        'invalid-email' : 'Amend or remove row from file',
        'invalid-org' : 'Amend or remove row from file',
        'missing-field' : 'Enter field(s) and retry'
    }

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [resultsData, setResultsData] = useState<ImportStatusError[]>(props.data?.errors ?? []);
    const [filteredData, setFilteredData] = useState<ImportStatus[]>([]);
    const [resultsPaginatedData, setResultsPaginatedData] = useState<ImportStatusError[]>([]);

    useEffect(() => {
        (async function () {
            if (props.data && props.data.errors) {
                setResultsData(props.data.errors);
            }
        })();
    }, [setResultsData, props, resultsData]);
    
    const filterFormSpecs: FilterSpecs[] = [
        { id: 'row', display: 'Row', type: 'text' },
        { id: 'error', display: 'Error', type: 'text' },
        { id: 'action', display: 'Action', type: 'text' },
    ];

    const sortHeadSpecs: SortHeadSpecs[] = [
        { id: 'row', display: 'Row' },
        { id: 'error', display: 'Error' },
        { id: 'action', display: 'Action' }
    ];
    
    return (
        <>      
            <AppTable.Filters
                    isFilterOpen={isFilterOpen}
                    setFilteredData={setFilteredData}
                    filterFormSpecs={filterFormSpecs}
                    resultsData={resultsData}
                    filteredData={filteredData}
                />
                <AppTable.Container>
                    <AppTable.FiltersToggleButton
                        isFilterOpen={isFilterOpen}
                        setIsFilterOpen={setIsFilterOpen}
                    />
                    <AppTable>
                        <AppTable.SortHead
                            sortHeadSpecs={sortHeadSpecs}
                            setResultsData={setResultsData}
                            resultsData={resultsData}
                            initialSortDirection="asc"
                            initialSortBy="name"
                        />
                        <AppTable.Body>
                            {resultsPaginatedData.map(
                                ({ row, error_reason }, i) => (
                                    <AppTable.Row key={i}>
                                        <AppTable.Cell>{row}</AppTable.Cell>
                                        <AppTable.Cell>{errorReasonEasyRead[error_reason as ImportMetaDataErrorReason]}</AppTable.Cell>
                                        <AppTable.Cell>{errorReasonRemediation[error_reason as ImportMetaDataErrorReason]}</AppTable.Cell>
                                    </AppTable.Row>
                                )
                            )}
                        </AppTable.Body>
                    </AppTable>
                    <AppTable.Pagination
                        resultsData={filteredData === undefined ? resultsData : filteredData}
                        setResultsPaginatedData={setResultsPaginatedData}
                    />
                </AppTable.Container>
        </>
    )
}

export default ImportFileInfo;
