import { isEmpty } from 'lodash';

const ApplyFilters = ({ setSearch, search, filterFormSpecs }) => {
    const filterValues = {};

    const handleTextFilter = filterSpec => {
        const filterSpecId = filterSpec.id;
        const domElement = document.querySelector(`#${filterSpecId}`);
        const valueTrimmed = domElement.value.trim();
        if (valueTrimmed) {
            if (
                search &&
                search[filterSpecId] &&
                !search[filterSpecId].find(term => term === valueTrimmed)
            ) {
                filterValues[filterSpecId] = search[filterSpecId].concat([valueTrimmed]);
            } else if (filterSpec && isEmpty(search[filterSpecId])) {
                filterValues[filterSpecId] = [valueTrimmed];
            }
        }
        domElement.value = '';
    };

    const handleRadioAndSelectFilter = filterSpec => {
        if (search?.[filterSpec.id]) {
            filterValues[filterSpec.id] = search[filterSpec.id];
        }

        const domElement = document.querySelectorAll(`[name='${filterSpec.id}']`);

        Object.values(domElement)
            .filter(({ checked }) => checked)
            .forEach(checkedItems => {
                filterValues[filterSpec.id] = filterValues[filterSpec.id] ?? [];
                checkedItems.checked = false;

                if (filterValues[filterSpec.id].indexOf(checkedItems.value) === -1) {
                    filterValues[filterSpec.id].push(checkedItems.value);
                }
            });
    };

    const filterTypes = [
        { radio: handleRadioAndSelectFilter },
        { select: handleRadioAndSelectFilter },
        { text: handleTextFilter }
    ];

    const setFilterValues = () => {
        filterFormSpecs.forEach(filterSpec =>
            filterTypes.find(item => (item[filterSpec.type] ? item[filterSpec.type](filterSpec) : null))
        );
    };

    const handleApplyFilter = () => {
        setFilterValues();
        setSearch({ ...search, ...filterValues });
    };

    const handleMouseClick = event => {
        handleApplyFilter();
        event.currentTarget.blur();
    };

    const handleKeyPress = event => {
        event.preventDefault();
        if (event.key === 'Enter') {
            handleApplyFilter();
        }
    };

    return (
        <button
            className="nhsuk-button"
            type="submit"
            onClick={handleMouseClick}
            onKeyPress={handleKeyPress}
        >
            Apply Filters
        </button>
    );
};

export default ApplyFilters;
