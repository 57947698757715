import React from 'react';
import styles from './pagination-link.module.scss';

const PaginationLink = ({ onClick, display, index, isCurrent, isDisabled }) => {
    if (isDisabled) {
        return <button className={`${styles.disabled} ${styles.paging_link}`}>{display}</button>;
    } else if (isCurrent) {
        return (
            <button
                className={`${styles.current} ${styles.paging_link}`}
                data-page-index={index}
                key={index}
            >
                {display}
            </button>
        );
    } else {
        return (
            <button
                className={`${styles.paging_link}`}
                data-page-index={index}
                key={index}
                onClick={onClick}
            >
                {display}
            </button>
        );
    }
};

export default PaginationLink;
