import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import axios from 'axios';

export interface ConfigState {
    apiBaseUrl: string;
    auth: AuthConfig;
    status: 'initial' | 'loading' | 'loaded';
}

export interface AuthConfig {
    serverBaseUrl: string;
    clientId: string;
    idps: Idp[];
}

export enum Idp {
    AzureAd = 'aad',
    Cognito = 'COGNITO'
}

const initialState: ConfigState = {
    apiBaseUrl: '',
    auth: {
        serverBaseUrl: '',
        clientId: '',
        idps: []
    },
    status: 'initial'
};

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {
    const response = await axios.get(`/config.json`);

    return response.data;
});

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchConfig.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchConfig.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.apiBaseUrl = action.payload.api_base_url;
                state.auth = {
                    serverBaseUrl: action.payload.auth.server_base_url,
                    clientId: action.payload.auth.client_id,
                    idps: action.payload.auth.idps
                };
            });
    }
});

export const selectStatus = (state: RootState) => state.config.status;
export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
