import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { Permission } from '@forms/console-types';
import React from 'react';
import { selectAuthState } from '../../../store/auth.slice';
import { useAppSelector } from '../../../app/hooks';

interface LinkProps extends RouterLinkProps {
    permission?: Permission;
}

export const Link: React.FC<LinkProps> = ({ permission, children, ...rest }) => {
    const auth = useAppSelector(selectAuthState);

    if (permission) {
        if (auth.permissions.includes(permission)) {
            return <RouterLink {...rest}>{children}</RouterLink>;
        }

        return <>{children}</>;
    }

    return <RouterLink {...rest}>{children}</RouterLink>;
};
