import React, { useEffect } from 'react';
import { refreshAccessToken, selectAuthState } from 'store/auth.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import { SessionExpired } from 'components/session-expired';
import { useLocation } from 'react-router-dom';

interface AuthProviderProps {
    children: React.ReactElement;
}

let refreshTokenTimer: ReturnType<typeof setInterval>;

export default function AuthProvider({ children }: AuthProviderProps) {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const { isExpired, isLoggedIn } = useAppSelector(selectAuthState);

    useEffect(() => {
        (async () => {
            if (isLoggedIn) {
                if (!refreshTokenTimer) {
                    refreshTokenTimer = setInterval(async () => {
                        await dispatch(refreshAccessToken());
                    }, 600000);
                }
            } else {
                if (refreshTokenTimer) {
                    clearInterval(refreshTokenTimer);
                }
            }
        })();
    }, [dispatch, isLoggedIn]);

    if (isExpired && location.pathname !== '/login') {
        return <SessionExpired />;
    }

    return children;
}
