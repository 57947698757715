import { Button, useBreadcrumbs } from 'components/app';
import { Input, Label } from 'nhsuk-react-components';
import React, { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useGetCollectionsQuery, usePostUserMutation } from '../../../services/console.api';

import { IdentityProvider } from '@forms/console-types/console-user';
import { PostUserApiRequest } from '@forms/console-types/api-requests';
import { RolesSelect } from './components/roles-select/roles-select';
import { UserRole } from '@forms/console-types';
import { lookUpRequestStatus } from './add-user-page.types';
import styles from './add-user-page.module.scss';
import { useHistory } from 'react-router-dom';
import { useShortCodeLookUp } from './add-user-page.hooks';
import { setTitle } from 'util/browser';

export const AddUserPage = (): ReactElement => {
    setTitle('Add Console User');
    useBreadcrumbs([
        {
            display: 'Console Users',
            path: '/users'
        },
        {
            display: 'Add User',
            path: '/users/add'
        }
    ]);

    const history = useHistory();
    const [shortCode, setShortCode] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [collections, setCollections] = useState<string[]>([]);
    const [userLookUp, lookUpStatus, setLookUpStatus, userDetails] = useShortCodeLookUp();
    const { data, isSuccess: isSuccessCollections } = useGetCollectionsQuery();
    const [postUser, { isSuccess: isSuccessPostUser }] = usePostUserMutation();

    useEffect(() => {
        if (isSuccessPostUser) history.push('/users');
    }, [isSuccessPostUser, history]);

    const handleSearch = () => userLookUp(shortCode);

    const handleShortCodeChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        evt.target && setShortCode(evt.target.value);
    };

    const handleRoleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        if (evt.target.type === 'radio') setRole(evt.target.value);

        if (evt.target.type === 'checkbox') {
            const collectionId = evt.target.value;
            if (collections.findIndex(id => id === collectionId) === -1) {
                setCollections([...collections, collectionId]);
                return;
            }
            setCollections([...collections.filter(id => id !== collectionId)]);
        }
    };

    const handleSubmit = (evt: SyntheticEvent): void => {
        evt.preventDefault();
        if (shortCode === '') {
            setLookUpStatus(lookUpRequestStatus.rejected);
            return;
        }
        const request: PostUserApiRequest = {
            short_code: shortCode,
            idp: IdentityProvider.Aad,
            roles: []
        };
        if (role === UserRole.Business && collections.length > 0) {
            collections.forEach(collection => {
                request.roles.push({
                    id: role,
                    collection_id: collection
                });
            });
        }
        if (role === UserRole.Admin || role === UserRole.Console) {
            request.roles.push({
                id: role
            });
        }
        postUser(request);
    };

    return (
        <>
            <h1>Add User</h1>
            <Label htmlFor="shortcode">NHS England Shortcode</Label>
            <span className={styles.inlineInputButton}>
                <Input
                    id="shortcode"
                    type="text"
                    width={30}
                    value={shortCode}
                    onChange={handleShortCodeChange}
                    error={lookUpStatus.status !== 'success' ? lookUpStatus.message : ''}
                />
                <Button onClick={handleSearch}>Look Up</Button>
            </span>
            <Label htmlFor="firstname">First name</Label>
            <Input
                id="firstname"
                type="text"
                width={30}
                value={userDetails?.first_name ?? ''}
                readOnly
            />
            <Label htmlFor="lastname">Last name</Label>
            <Input
                id="lastname"
                type="text"
                width={30}
                value={userDetails?.surname ?? ''}
                readOnly
            />
            <Label htmlFor="email">Email</Label>
            <Input id="email" type="text" width={30} value={userDetails?.email ?? ''} readOnly />
            {data && isSuccessCollections ? (
                <RolesSelect data={data} handleChange={handleRoleChange} />
            ) : (
                <p>loading...</p>
            )}
            <div className={styles.addUserButtons}>
                <Button onClick={handleSubmit}>Add</Button>
                <Button secondary to="/users">
                    Cancel
                </Button>
            </div>
        </>
    );
};
