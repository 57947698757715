import { Button, useBreadcrumbs } from 'components/app';
import React, { SyntheticEvent, useState } from 'react';
import { isFileTooLarge, isValidCsv } from './submitters-import-page.utils';

import { WarningCallout } from 'nhsuk-react-components';
import cn from 'classnames';
import { setTitle } from 'util/browser';
import styles from './submitters-import-page.module.scss';
import { useHistory } from 'react-router';
import { usePostSubmittersMutation } from 'services/console.api';

export function SubmittersImportPage() {
    setTitle('Import Submitters');
    useBreadcrumbs([
        {
            display: 'Submitters',
            path: '/submitters'
        },
        {
            display: 'Import Submitters',
            path: '/submitters/import'
        }
    ]);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [postSubmitters, { isError, error : serverErrorMessage }] = usePostSubmittersMutation();
    const message = 'You must supply a valid CSV file';
    const history = useHistory();
    
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>): void => {
        if (evt.target.files && evt.target.files.length && isValidCsv(evt.target.files[0])) {
            setSelectedFile(evt.target.files[0]);
            if (isFileTooLarge(evt.target.files[0])) {
                setErrorMessage('The file you\'ve selected is too large. Maximum file size is 5MB.');
                return
            }
            setErrorMessage(null);
            return;
        }
        setErrorMessage(message);
    };

    const handleSubmit = async (evt: SyntheticEvent): Promise<void> => {
        evt.preventDefault();
        if (selectedFile !== null) {
            const formData = new FormData();
            formData.append('X-File-Name', selectedFile);
            const submitterImportPost = await postSubmitters(formData);
            if ('data' in submitterImportPost) {
                history.push(`/submitters/import/feedback/${submitterImportPost.data}`);
            }
            return;
        }
        setErrorMessage(message);
    };

    const handleServerError = () => {
        if (serverErrorMessage) {
            if ('status' in serverErrorMessage && serverErrorMessage['status'] === 400) {
                const errMsg = 'error' in serverErrorMessage ? serverErrorMessage['error'] : JSON.stringify(serverErrorMessage.data)
                return (JSON.parse(errMsg).validation_error);

            } else if ('status' in serverErrorMessage && serverErrorMessage.status === 413) {
                return ('Failed to upload. Please select a file less than 5MB in size.');

            } else {
                return ('There was an error uploading your file. Please try again.');
            }
        }
    };

    return (
        <>  
            <WarningCallout>
                <WarningCallout.Label>Import Submitters Template</WarningCallout.Label>
                <p>
                You can download a correctly formatted template to Import Submitters by clicking the <a href="../static/media/submitter-import-template.csv" target="_blank">Import Submitters CSV Template</a> link
                </p>
            </WarningCallout>
            <h1>Import Submitters</h1>
            <div
                className={cn(
                    styles.fileUpload,
                    'nhsuk-form-group',
                    (errorMessage || isError) && 'nhsuk-form-group--error'
                )}
            >
                <label className={cn('nhsuk-label', styles.fileUploadLabel)} htmlFor="file-upload">
                    New Upload File
                </label>

                {(errorMessage && !isError ) && (
                    <div className={styles.validationError}>
                        <p>{errorMessage}</p>
                    </div>
                )}

                {(isError) && (
                    <div className={styles.validationError}>
                        <p>{handleServerError()}</p>
                    </div>
                )}

                <input
                    className={styles.fileUploadInput}
                    type="file"
                    onChange={handleChange}
                    id="file-upload"
                />
            </div>
            <div className={styles.fileUploadImportControls}>
                <Button onClick={handleSubmit}>Import</Button>
                <Button secondary to="/submitters">
                    Cancel
                </Button>
            </div>
        </>
    );
}

export default SubmittersImportPage;
